<template>
  <div class="card">
    <DataTable
      :value="dataList"
      showGridlines
      responsiveLayout="stack"
      breakpoint="1010px"
      class="p-datatable-sm p-2 text-sm"
      contextMenu
      v-model:contextMenuSelection="selectedItem"
      @rowContextmenu="onRowContextMenu"
      :paginator="true"
      :rows="10"
      v-model:filters="filters"
      :globalFilterFields="['name','description','full_name']"
    >
      <template #header>
          <div class="p-d-flex p-jc-between">
              <Button type="button" icon="pi pi-filter-slash" label="Limpiar" class="p-button-outlined p-button-sm" @click="clearFilter()"/>
              <span class="p-input-icon-left">
                  <i class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Búsqueda" class="p-input-sm" />
              </span>
          </div>
      </template>
      <template #empty>
          no hay informacion para mostrar.
      </template>
      <template #loading>
          cargando información. Por favor espere.
      </template>
      <Column field="name" header="Permiso" style="font-size: 0.875rem !important; width:20%;"/>
      <Column field="description" header="Descripción" style="font-size: 0.875rem !important; width:30%;"/>
      <Column field="full_name" header="Usuario" style="font-size: 0.875rem !important; text-transform: capitalize !important; width:30%;">
        <template #body="{data}">
          <p class="camelCase">
            {{ data.full_name.toLowerCase() }}
          </p>
        </template>
      </Column>
      <Column v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.permisos.access')" header="Estado" headerStyle=" text-align: center" bodyStyle="text-align: center; overflow: visible" style="font-size: 0.875rem !important; width:10%;">
        <template #body="{data}">
          <InputSwitch v-model="data.state" @change="handleChangeState(data)" />
        </template>
      </Column>
      <Column v-if="$can('pharmasan.contabilidad.amortizaciones.configuracion.permisos.access')" header="Opcciones" headerStyle=" text-align: center" bodyStyle="text-align: center; overflow: visible" style="font-size: 0.875rem !important; width:10%;">
        <template #body="{data}">
          <Button icon="pi pi-trash" title="Eliminar" class="p-button-rounded p-button-danger"
                  @click="deleteProduct(data)"/>
        </template>
      </Column>
    </DataTable>
    <ContextMenu :model="Butonitems" ref="contextMenu" />
  </div>
</template>
<script>
import { ref, onMounted } from 'vue' // defineAsyncComponent
import { useDataTable } from './useDataTable'
import { messageWarningDelete } from '@/libs/mensajes'
import ApiDelete from '@/apps/pharmasan/accounting/amortizations/services/detelePermissions'
import ApiUpdatePermissions from '@/apps/pharmasan/accounting/amortizations/services/updatePermissions'
import { useToast } from 'primevue/usetoast'
import { FilterMatchMode } from 'primevue/api'
export default {
  name: 'dataTable',
  components: {},
  setup () {
    const toast = useToast()
    const { getDataList, dataList } = useDataTable()
    // data
    const contextMenu = ref()
    const selectedItem = ref()
    const Butonitems = [
      {
        label: 'Actualizar',
        icon: 'pi pi-fw pi-pencil',
        command: () => viewProduct(selectedItem.value)
      },
      {
        label: 'Eliminar',
        icon: 'pi pi-fw pi-times',
        command: () => deleteProduct(selectedItem.value)
      }
    ]
    const modalNotifications = ref()
    const filters = ref({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS }

    })
    // hooks
    onMounted(() => {
      getDataList()
    })
    // methods
    const onRowContextMenu = (event) => contextMenu.value.show(event.originalEvent)
    const viewProduct = (row) => {
      modalNotifications.value.toggle(1)
      modalNotifications.value.defineUserNotification(row)
    }
    const deleteProduct = async (row) => {
      const { isConfirmed } = await messageWarningDelete('', 'Eliminar registro', 'warning', '¿Esta seguro de eliminar el registro')
      if (isConfirmed) handleRemoveItemList(row.permissionActionId)
    }
    const handleRemoveItemList = async (id) => {
      const { data } = ApiDelete(id).catch(err => console.error(err))
      await getDataList()
      if (data) toast.add({ severity: 'success', summary: 'Registro Eliminado', detail: 'Eliminado con exíto', life: 3000 })
    }
    const clearFilter = () => {
        initfilters()
    }
    const initfilters = () => {
        filters.value = {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS }
        }
    }
    const handleChangeState = async (row) => {
      const { isConfirmed } = await messageWarningDelete('', 'Modificar permiso', 'warning', `¿Esta seguro de ${ row.state ? 'Activar' : 'Inactivar' } el Permiso ${ row.name } para ${ row.full_name }`)
      if (isConfirmed) {
          await handleUpdatedState(row.state, row.permissionActionId)
      } else {
        await getDataList()
      }
    }
    const handleUpdatedState = async (state, id) => {
      const { data } = await ApiUpdatePermissions(id, { state })
      await getDataList()
      if (data) toast.add({ severity: 'success', summary: 'Permiso', detail: 'Actualizado con exíto', life: 3000 })
    }
    // return
    return {
      dataList,
      Butonitems,
      contextMenu,
      selectedItem,
      onRowContextMenu,
      deleteProduct,
      viewProduct,
      modalNotifications,
      filters,
      clearFilter,
      initfilters,
      handleChangeState
    }
  }
}
</script>

<style scoped>
.camelCase p {
  text-transform: capitalize;
}
.firstLetter p {
  text-transform: uppercase;
}

.firstLetter p:first-letter {
  text-transform: uppercase;
}
::v-deep(.p-datatable-scrollable .p-datatable-thead > tr > th){
  display: flex;
  align-items: center;
  flex: auto;
}
::v-deep(.p-datatable-scrollable .p-datatable-tbody > tr > td) {
  display: flex;
  flex: auto;
  align-items: center;
}
</style>
